<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div
        v-if="userData && (userData.group_id < 100)"
        class="col-3 d-flex align-items-center"
      >
        <span
          class="text-right"
        >Tahun<br>Anggaran</span>
        <v-select
          v-model="year"
          required
          :clearable="false"
          disabled
          name="year_budget"
          :options="year_budget"
          placeholder="Tahun Anggaran"
          class="ml-1 flex-fill"
          @input="changeYear"
        />
      </div>
      <b-link
        v-if="userData && (userData.group_id < 100)"
        :to="{ name: 'pages-search' }"
        class="nav-link nav-link-search"
      >
        <feather-icon
          icon="SearchIcon"
          size="21"
        />
      </b-link>
      <b-link
        :to="{ name: 'pages-helpdesk' }"
        class="nav-link nav-link-search"
      >
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
        />
      </b-link>
      <b-link
        :to="{ name: 'pages-faq' }"
        class="nav-link nav-link-search"
      >
        <feather-icon
          icon="BookOpenIcon"
          size="21"
        />
      </b-link>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-lg-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    DarkToggler,
    UserDropdown,
    vSelect,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const date1 = 2024
    return {
      year: Number(localStorage.getItem('tahunAnggaran')),
      year_budget: [date1, date1 + 1],
      userData: (getUserData()) ? getUserData() : null,
    }
  },
  methods: {
    changeYear() {
      localStorage.setItem('tahunAnggaran', this.year.toString())
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
